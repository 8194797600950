<template>
  <div>
    <div v-if="token" class="profile-mis-direcciones">
      <div id="add-new-address" class="col-12 col-md-4 p-2">
        <div class="add-new-address" @click="addNewDir()">
          <div class="d-block text-center content-add-new-address">
            <img src="@/assets/icons/icon-circle-plus.svg" alt="" />
            <p>Agregar nueva dirección</p>
          </div>
        </div>
      </div>
      <div
        v-for="(dir, index) in directions"
        :key="index"
        class="col-12 col-md-4 p-2"
      >
        <div class="container-card-address">
          <div class="container-opt-dir">
            <span @click="editDir(dir)">Editar</span>
            <span
              v-if="directions.length > 1"
              style="color: tomato; margin-right: 0"
              @click="deleteDir(dir.direccion_id)"
              >Eliminar</span
            >
          </div>
          <p>
            {{ dir.direccion }} <br />
            {{ dir.barrio }} <br />
            {{
              dir.ciudad_texto
                .split(',')[0]
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }},
            {{
              dir.ciudad_texto
                .split(',')[1]
                .trim()
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
            <br />
            <!-- {{dir.observaciones}}  -->
          </p>
        </div>
      </div>
    </div>
    <div v-else class="profile-mis-direcciones py-5">
      <div
        class="container-bag-empty"
        style="height: 50vh; background-color: white"
      >
        <div class="text-center">
          <h3>Aún no has iniciado sesión</h3>
          <p class="fs-6">
            Para poder acceder a este modulo primero debes iniciar sesión.
          </p>
          <div v-if="!token" class="mt-4" @click="$bvModal.show('modal-login')">
            <button class="btn-primary px-4 fs-6">Iniciar sesión</button>
          </div>
        </div>
      </div>
    </div>
    <footer-Returns />
    <modal-new-direction :data-dir="dataTemp" :is-edit="isEdit" />
  </div>
</template>
<script>
import FooterReturns from '@/components/Returns/FooterReturns'
import ModalNewDirection from '@/components/Templates/ModalNewDirection'

import { mapActions, mapState } from 'vuex'
export default {
  components: { FooterReturns, ModalNewDirection },
  data() {
    return {
      dataTemp: {},
      isEdit: false,
      token: null,
    }
  },
  computed: {
    ...mapState('user', ['directions', 'dataUser']),
  },
  mounted() {
    this.token = window.localStorage.getItem('accessToken')
  },
  methods: {
    ...mapActions('user', ['deleteDirUser', 'getProfile']),
    editDir(dir) {
      this.isEdit = true
      this.dataTemp = dir
      this.$bvModal.show('modal-new-direction')
    },
    addNewDir() {
      this.isEdit = false
      this.dataTemp = {}
      this.$bvModal.show('modal-new-direction')
    },
    async deleteDir(id_dir) {
      await this.deleteDirUser(id_dir)
      this.getProfile()
    },
  },
}
</script>
