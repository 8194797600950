<template>
  <b-modal id="modal-new-direction" size="lg" centered hide-header hide-footer>
    <div class="d-flex flex-wrap p-4 p-md-5">
      <h5 class="mb-4 w-100">
        {{ isEdit ? 'Editar dirección' : 'Añadir nueva dirección' }}
      </h5>
      <!-- <div class="col-12 position-relative">
                <input class="payment-input input-login mb-4" placeholder="Nombre completo del destinatario" type="text"  v-model="nombreDestinatario">
                <span v-if="nombre_validate" class="error-inputs">nombre invalido</span>
                <span class="newlatter-error-input" v-if="nombreDestinatario">Nombre completo del destinatario</span>
            </div> -->
      <div class="col-12 col-md-6 pe-md-3 position-relative">
        <input
          v-model="direccion"
          class="payment-input input-login mb-4"
          placeholder="Dirección"
          type="street-address"
          maxlength="50"
        />
        <span v-if="direccion_validate" class="error-inputs pe-md-3"
          >Dirección invalida</span
        >
        <span v-if="direccion" class="newlatter-error-input">Dirección</span>
      </div>
      <div class="col-12 col-md-6 ps-md-3 position-relative">
        <input
          v-model="barrio"
          class="payment-input input-login mb-4"
          placeholder="Barrio"
          type="text"
          maxlength="50"
        />
        <span v-if="barrio_validate" class="error-inputs">Barrio invalido</span>
        <span v-if="barrio" class="newlatter-error-input" style="left: 1.5rem"
          >Barrio</span
        >
      </div>
      <div class="col-12 col-md-6 pe-md-3 position-relative">
        <select
          v-model="dep"
          class="payment-input payment-input-right ms-0 w-100"
        >
          <option :value="null">Departamento</option>
          <option v-for="(depa, index) in deps" :key="index" :value="depa">{{
            depa.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
          }}</option>
        </select>
        <span v-if="dep_validate" class="error-inputs pe-md-3" style="top: 64%"
          >Departamento requerido</span
        >
        <span v-if="dep" class="newlatter-error-input">Departamento</span>
      </div>
      <div class="col-12 col-md-6 ps-md-3 position-relative">
        <select
          v-model="mun"
          class="payment-input payment-input-right ms-0 w-100"
        >
          <option :value="null">Municipio</option>
          <option v-for="(city, index) in citys" :key="index" :value="city">{{
            city.descripcion.toLowerCase().replace(/^\w/, d => d.toUpperCase())
          }}</option>
        </select>
        <span v-if="mun_validate" class="error-inputs" style="top: 64%"
          >Municipio requerido</span
        >
        <span v-if="mun" class="newlatter-error-input" style="left: 1.5rem"
          >Municipio</span
        >
      </div>
      <div class="col-12 position-relative">
        <input
          v-model="observaciones"
          class="payment-input input-login mb-4"
          placeholder="Observaciones"
          type="text"
          maxlength="50"
        />
        <span v-if="observaciones" class="newlatter-error-input"
          >Observaciones</span
        >
      </div>
      <div class="col-12 col-md-5 mx-auto">
        <button
          class="secondary-button w-100 mt-3"
          @click="isEdit ? updateDir() : addnewAddress()"
        >
          {{ isEdit ? 'Guardar cambios' : 'Agregar dirección' }}
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    dataDir: {
      type: Object,
      required: false,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // nombreDestinatario: '',
      direccion: '',
      barrio: '',
      dep: null,
      mun: null,
      observaciones: '',
      direccion_id: '',
      citys: [],
      /* Variables de validaciones */
      nombre_validate: false,
      direccion_validate: false,
      barrio_validate: false,
      dep_validate: false,
      mun_validate: false,
      /******************************/
    }
  },
  computed: {
    ...mapState('products', ['deps']),
    ...mapState('user', ['errorDirection']),
  },
  watch: {
    dataDir() {
      if (this.isEdit) {
        this.updateInfoAddress()
      } else {
        // this.nombreDestinatario =  '';
        this.direccion = ''
        this.barrio = ''
        this.dep = null
        this.mun = null
        this.observaciones = ''
      }
      this.nombre_validate = false
      this.direccion_validate = false
      this.barrio_validate = false
      this.dep_validate = false
      this.mun_validate = false
    },
    dep() {
      this.mun = null
      if (this.dep) {
        this.citys = this.dep.ciudades
      }
    },
  },
  mounted() {
    if (this.isEdit) {
      this.updateInfoAddress()
    }
    this.nombre_validate = false
    this.direccion_validate = false
    this.barrio_validate = false
    this.dep_validate = false
    this.mun_validate = false
    if (!(this.deps.length > 0)) {
      this.getDataCitys()
    }
  },
  methods: {
    ...mapActions('products', ['getDataCitys']),
    ...mapActions('user', ['addNewDirection', 'updateDirection']),
    async addnewAddress() {
      if (
        /*this.nombreDestinatario.length >= 4 && */ this.direccion.length >=
          4 &&
        this.dep &&
        this.mun
      ) {
        await this.addNewDirection({
          /*destinatario: this.nombreDestinatario, */ direccion: this.direccion,
          barrio: this.barrio,
          departamento_id: this.dep.codigo,
          ciudad_id: this.mun.codigo,
          observaciones: this.observaciones,
        }).then(() => {
          if (!this.errorDirection) {
            this.$bvModal.hide('modal-new-direction')
          }
        })
      } else {
        // this.nombre_validate = !(this.nombreDestinatario.length >= 4);
        this.direccion_validate = !(this.direccion.length >= 4)
        this.barrio_validate = !(this.barrio.length >= 4)
        this.dep_validate = !this.dep
        this.mun_validate = !this.mun
      }
    },
    async updateDir() {
      if (
        /*this.nombreDestinatario.length >= 4 && */ this.direccion.length >=
          4 &&
        this.barrio.length >= 4 &&
        this.dep &&
        this.mun
      ) {
        await this.updateDirection({
          /*destinatario: this.nombreDestinatario, */ direccion: this.direccion,
          barrio: this.barrio,
          departamento_id: this.dep.codigo,
          ciudad_id: this.mun.codigo,
          observaciones: this.observaciones,
          direccion_id: this.direccion_id,
        }).then(() => {
          if (!this.errorDirection) {
            this.$bvModal.hide('modal-new-direction')
          }
        })
      } else {
        // this.nombre_validate = !(this.nombreDestinatario.length >= 4);
        this.direccion_validate = !(this.direccion.length >= 4)
        this.barrio_validate = !(this.barrio.length >= 4)
        this.dep_validate = !this.dep
        this.mun_validate = !this.mun
      }
    },
    async updateInfoAddress() {
      // this.nombreDestinatario =  this.dataDir.destinatario;
      this.direccion = this.dataDir.direccion
      this.barrio = this.dataDir.barrio
      this.dep = await this.deps.find(
        e => e.codigo == this.dataDir.departamento_id,
      )
      this.mun = await this.dep.ciudades.find(
        e => e.codigo == this.dataDir.ciudad_id,
      )
      this.observaciones = this.dataDir.observaciones
      this.direccion_id = this.dataDir.direccion_id
    },
  },
}
</script>
