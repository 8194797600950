<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <profile-mis-direcciones class="container-profile-page" />
    </div>
  </div>
</template>
<script>
import BaseProfile from '@/components/Profile/BaseProfile'
import ProfileMisDirecciones from '@/components/Profile/ProfileMisDirecciones'
export default {
  components: { BaseProfile, ProfileMisDirecciones },
  mounted() {
    document.title = 'Mis direcciones | DEREK Tienda Online'
    // this.$gtag.pageview(this.$route.fullPath)
    // if(!window.localStorage.getItem('accessToken')){
    //     this.$router.replace({name: 'Home'})
    // }
  },
}
</script>
